import React, { useEffect, useRef } from 'react';
import backgroundVideo from '../Videos/backgroundVideo.mp4';
import '../CSS/BackGroundVid.css'



function BackGroundVideo() {
    const videoRef = useRef(null);
  
    useEffect(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.load();
        const playPromise = videoElement.play();
  
        
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error('Video playback failed:', error);
          });
        }
      }
  
      const handleTouchStart = () => {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play();
        }
      };
  
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          if (videoRef.current && videoRef.current.paused) {
            videoRef.current.play();
          }
        }
      };
  
      window.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        window.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);
  
  
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <video
                ref={videoRef}
                src={backgroundVideo}
                autoPlay
                muted
                loop
                playsInline
                preload='auto'
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    minHeight: '100vh', 
                }}
            />
            <div className="title">
                <div className="left">
                    <div className="large-text">Artisans carreleurs</div>
                    <div className="small-text">en</div>
                    <div className="large-text">
                        <span className="charente">Charente</span>
                        <span className="maritime">-Maritime</span>
                    </div>
                    <div className="small-text">à</div>
                    <div className="large-text">
                        <span className="la">La</span>
                        <span className="rochelle"> Rochelle</span>
                    </div>
                    <div className="small-text">et sur</div>
                    <div className="large-text">l'Île de Ré</div>
                </div>

                <div className="right">
                  <p>
                    Les travaux de carre&shy;lage en Charente-maritime à La Rochel&shy;le et sur l'Île de Ré donn&shy;eront
                    une nouvel&shy;le dimension à votre mais&shy;on, appartement ou local comm&shy;ercial. Que ce soit pou&shy;r
                    une rénova&shy;tion, une construc&shy;tion ou un réaménage&shy;ment, les revêt&shy;ements choisis peuve&shy;nt
                    complète&shy;ment changer l'esthét&shy;ique de votre espa&shy;ce de vie. Notre équi&shy;pe de professio&shy;nnels
                    du carre&shy;lage en Char&shy;ente-marit&shy;ime à La Rochel&shy;le et sur l'Île de Ré vous aide à chois&shy;ir
                    le revêtem&shy;ent idéal pour votre pro&shy;jet. Contactez-nous pour des trav&shy;aux de carr&shy;elage réussis.
                  </p>
                </div>
            </div>
        </div>
    );
}

export default BackGroundVideo;

    
    
    