import React, { useEffect } from 'react';

const LegalNotice = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="legal">
      <h1>Informations légales</h1>
      <h2>1. PRÉSENTATION DU SITE.</h2>
      <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site lesartisanscharentais.com l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
      <p>Propriétaire : Les Artisans Charentais 2 Rue Robert Geffré, 17000 La Rochelle N°Siret: 91990132200017</p>
      <p>Hébergeur : OVH – 2 rue Kellermann - 59100 Roubaix - France</p>
      <p>Localisation du serveur d'hébergement : France</p>

      <h2>2. CONDITIONS GÉNÉRALES D’UTILISATION DU SITE ET DES SERVICES PROPOSÉS.</h2>
      <p>L’utilisation du site implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site lesartisanscharentais.com sont donc invités à les consulter de manière régulière.</p>
      <p>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Les Artisans Charentais, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>
      <p>Le site lesartisanscharentais.com est mis à jour régulièrement. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
      
      <h2>3. DESCRIPTION DES SERVICES FOURNIS.</h2>
      <p>Le site lesartisanscharentais.com a pour objet de fournir une information concernant l’ensemble des activités de la société.</p>
      <p>Les Artisans Charentais s’efforce de fournir sur le site lesartisanscharentais.com des informations aussi précises que possible. Les renseignements figurant sur le site lesartisanscharentais.com ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>

      <h2>4. LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES.</h2>
      <p>Le site utilise la technologie JavaScript.</p>
      <p>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour</p>

      <h2>5. PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS.</h2>
      <p>Les Artisans Charentais est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>

      <h2>6. LIMITATIONS DE RESPONSABILITÉ.</h2>
      <p>Les Artisans Charentais ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site lesartisanscharentais.com, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
      <p>Les Artisans Charentais ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site lesartisanscharentais.com.</p>
      <p>Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. Les Artisans Charentais se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, Les Artisans Charentais se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).</p>

      <h2>7. GESTION DES DONNÉES PERSONNELLES.</h2>

      <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
      <p>A l'occasion de l'utilisation du site lesartisanscharentais.com, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site lesartisanscharentais.com, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.</p>
      <p>En tout état de cause Les Artisans Charentais ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site lesartisanscharentais.com. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site lesartisanscharentais.com l’obligation ou non de fournir ces informations.</p>
      <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.

      Aucune information personnelle de l'utilisateur du site lesartisanscharentais.com n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de Les Artisans Charentais et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site lesartisanscharentais.com.

      Vous avez le droit de vous inscrire sur la liste d'opposition au démarchage téléphonique, disponible à cette adresse: Bloctel.gouv.fr.

      Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
      </p>

      <h2>9. DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION.</h2>

      <p>Tout litige en relation avec l’utilisation du site lesartisanscharentais.com est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de La Rochelle.</p>

      <h2>10. LES PRINCIPALES LOIS CONCERNÉES.</h2>

      <p>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</p>

      <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</p>

      <h2>11. LEXIQUE.</h2>

      <p>Utilisateur : Internaute se connectant, utilisant le site susnommé.

      Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
      </p>
    </div>
  )
}

export default LegalNotice;